import "./App.css";
import TaskList from "./components/Task/TaskList";
import ToDoForm from "./components/ToDoForm";
import React, { useState } from "react";

function App() {
  const [tasks, setTasks] = useState([]);

  // const tasks = [
  //   { id: 1, content: "this is my first task" },
  //   { id: 2, content: "this is my second task" },
  //   { id: 3, content: "Lastly, this is my third task" },
  // ];

  const onSubmitHandler = (text) => {
    setTasks([...tasks, { id: Math.random() * 100000, content: text }]);
  };

  const onDeleteHandler = (id) => {
    console.log(id);

    setTasks(tasks.filter((task) => task.id !== id));
  };

  return (
    <div className="App">
      <header className="App-header">Enter some tasks:</header>
      <ToDoForm onSubmit={onSubmitHandler} />
      <TaskList tasks={tasks} onDelete={onDeleteHandler} />
    </div>
  );
}

export default App;

import { Card } from "antd";
import React from "react";
import classes from "./Task.module.css";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const Task = ({ content, onDelete }) => {
  return (
    <div>
      <Card
        className={classes.card}
        style={{ width: 300 }}
        actions={[
          <DeleteOutlined key="delete" onClick={onDelete} />,
          <EditOutlined key="edit" />,
        ]}
      >
        {content}
      </Card>
    </div>
  );
};

export default Task;

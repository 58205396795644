import React, { useRef } from "react";
import classes from "./ToDoForm.module.css";

const ToDoForm = ({ onSubmit }) => {
  const inputRef = useRef();

  const onSubmitHandler = (event) => {
    event.preventDefault();
    const text = inputRef.current.value;
    inputRef.current.value = "";

    if (!text) {
      console.log("Enter some text");
      return;
    }

    onSubmit(text);
  };

  return (
    <form className={classes.form} onSubmit={onSubmitHandler}>
      <textarea
        className={classes.input}
        placeholder="Text"
        type="text"
        ref={inputRef}
      />
      <button>Enter</button>
    </form>
  );
};

export default ToDoForm;

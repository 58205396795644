import React from "react";
import Task from "./Task";
import classes from "./TaskList.module.css";

const TaskList = ({ tasks, onDelete }) => {
  return (
    <div className={classes.list}>
      {tasks.map((task) => (
        <Task
          key={task.id}
          content={task.content}
          onDelete={() => onDelete(task.id)}
        />
      ))}
    </div>
  );
};

export default TaskList;
